import React, { useRef } from 'react';
import defaultCertificationImage from "../img/default_images/foodchain_certifications.png"
import { useConfig } from '../ConfigContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Certifications = ({ qr_code_hash, data }) => {

  const scrollerRef = useRef(null);

  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const config = useConfig();
  const { t } = useTranslation();


  const linkToCertification = (c) => {

    var link = null;

    if(c && c.files && c.files.length>0){
       link = config.URL_DOMAIN + qr_code_hash + "/certification/" + c.files[0].document_sha256sum + "/" + encodeURIComponent(window.location.href);
    }
    else if(c && c.document){
       link = config.URL_DOMAIN + qr_code_hash + "/certification/" + c.document_sha256sum + "/" + encodeURIComponent(window.location.href);
    }

    return link;
    /*
    if(c && c.files && c.files.length>0){
      var link = config.ROOT_DOMAIN + c.files[0].document;
      window.open(link, '_blank');
    }
    else if(c && c.document){
      var link = config.ROOT_DOMAIN + c.document;
      window.open(link, '_blank');
    }
    */
  }

  const onMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollerRef.current.offsetLeft;
    scrollLeft.current = scrollerRef.current.scrollLeft;
  };

  const onMouseLeave = () => {
    isDragging.current = false;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };

  const onMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - scrollerRef.current.offsetLeft;
    const walk = (x - startX.current) * 2; // Adjust scrolling speed as needed
    scrollerRef.current.scrollLeft = scrollLeft.current - walk;
  };


  var object = data;

  if(object===null || typeof object === 'undefined' || object.length===0){
    return <div></div>;
  }




  return (
    <div>
    <h2>{t('certificati')}</h2>
    <div
      className="supply-chain-scroller"
      ref={scrollerRef}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >

      {
      object.map((item, index) => (
        <Link to={linkToCertification(item)}>
        <div className="certification-icon-outer" key={index}>
           <div>
            <img alt="certification" src={ (item && item.images && item.images[0]) ? config.DOMAIN+item.images[0].img_low_cropped : defaultCertificationImage } />
           </div>
           <div className="certification-name">
            {item.name ? item.name : ""}
           </div>
        </div>
        </Link>
      ))}


    </div>
    </div>
  );
};

export default Certifications;
