import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Define the maximum length for the initial text display
const MAX_LENGTH = 200;

const ExpandableText = ({ title, text }) => {

  const { t } = useTranslation();

  // State to track whether the full text is shown
  const [isExpanded, setIsExpanded] = useState(false);

  // Determine if the text is longer than MAX_LENGTH
  const isTextLong = text.length > MAX_LENGTH;

  // Function to handle the button click
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <h2>{title}</h2>
      {/* Display truncated or full text based on the state */}
      <p>
        {isExpanded ? text : text.substring(0, MAX_LENGTH) + (isTextLong ? '...' : '')}
      </p>

      {/* Show "Read More" button if the text is long and not expanded */}
      {isTextLong && !isExpanded && (
        <div className="big-button-container">
          <button className="big-button gtrans" onClick={handleToggle}>{t('read_more')}</button>
        </div>
      )}

    </div>
  );
};

export default ExpandableText;
