import React, { useState, useEffect, useCallback } from 'react';
import PageContainer from '../components/PageContainer';
import FooterNavigator from '../components/FooterNavigator';
import SupplyChainScroller from '../components/SupplyChainScroller';
import TitleHeader from '../components/TitleHeader';
import { useConfig } from '../ConfigContext';
import MapRoute from '../components/MapRoute';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';
import {execute_RTL, google_translate_all} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';

import defaultProductImage from '../img/default_images/foodchain_products.png';

import { useTranslation } from 'react-i18next';

const SupplyChainPage = ({ qr_code_hash, data, downward_data }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const [itemIndex, setItemIndex] = useState(0);

  const [supplyChainItems, setSupplyChainItems] = useState([]);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productLotNumber, setProductLotNumber] = useState(null);
  const [mapPositions, setMapPositions] = useState([]);
  const [productUrl, setProductUrl] = useState(null);


  const metadata = useMetadata();

  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, []);



  const displaySupplyChainItem = useCallback((index) => {
    if(data && data.products && data.batch){


      if(supplyChainItems && supplyChainItems[index] && supplyChainItems[index]){


        var the_p = supplyChainItems[index];
        setProductName(the_p.name);
        setProductDescription(the_p.description);

        setProductUrl(config.URL_DOMAIN + the_p.item_id.substring(2));


        var candidates = data.batch.filter((b) => {
          return b.foodchain_batch.parents.includes(the_p.item_id);
        });
        if(candidates && candidates.length > 0){
          var the_batch = candidates[0];
          setProductLotNumber(the_batch.foodchain_batch.internal_number);
          setProductUrl(config.URL_DOMAIN + the_batch.foodchain_batch.item_id.substring(2));
        }
      }
    };

    execute_RTL();
  }, [config.URL_DOMAIN, data, supplyChainItems]); // Add any dependencies that 'handleItemClick' relies on




  const selectSupplyChainItem = (index) => {
    //console.log("selectSupplyChainItem "+index);
    setItemIndex(index);
  };



  function haveNonEmptyIntersection(arr1, arr2) {
      for (var i = 0; i < arr1.length; i++) {
          for (var j = 0; j < arr2.length; j++) {
              if (arr1[i] === arr2[j]) {
                  return true; // Return true if a common element is found
              }
          }
      }
      return false; // Return false if no common element is found
  }

  const removeDuplicatesByItemId = (arr) => {
    const seen = new Set();
    return arr.filter(item => {
      if (!seen.has(item.item_id)) {
        seen.add(item.item_id);
        return true;  // Keep this item
      }
      return false;  // Filter out duplicate
    });
  };

  useEffect(() => {

    //console.log("SupplyChainPage useEffect");


    var the_positions = [];

    if(downward_data && downward_data.products && downward_data.products.length > 0){
      var prods = downward_data.products;
      var batches = downward_data.batch;
      var batches_with_transfer_sons = batches.filter((b)=>b.foodchain_batch.has_transfer_son).map((b)=>b.foodchain_batch.item_id);

      prods = prods.filter((p)=>{
        return haveNonEmptyIntersection(p.foodchain_product.sons, batches_with_transfer_sons)
      });

      prods = prods.concat([downward_data.product]);

      var items = prods.map((p) => {
        if(!p) return null;

        if(p.foodchain_product && p.foodchain_product.computed_coords) {
          the_positions.push(p.foodchain_product.computed_coords)
        }

        var item_name = p.foodchain_product.name;
        var item_id = p.foodchain_product.item_id;
        var depth = p.foodchain_product.depth;
        var mdepth = p.foodchain_product.mdepth;
        var item_description = p.foodchain_product.description;
        var item_img = defaultProductImage;
        if(p.foodchain_product.images && p.foodchain_product.images.length > 0){
          item_img = config.DOMAIN + p.foodchain_product.images[0].img_low_cropped;
        }
        return {
          'label': item_name,
          'imageUrl': item_img,
          'item_id': item_id,
          'description': item_description,
          'depth': depth,
          'mdepth': mdepth,
        };
      }).filter(item => item !== null);

      items = removeDuplicatesByItemId(items);

      items.sort((a, b) => {
        // First, sort by 'mdepth'
        if (a.mdepth !== b.mdepth) {
          return b.mdepth - a.mdepth;
        }
        // If 'mdepth' is the same, sort by 'depth'
        return b.depth - a.depth;
      });

      setSupplyChainItems(items);
      setMapPositions(the_positions);

      console.log(items);
    }
  }, [downward_data, config.DOMAIN]);




  useEffect(() => {

      displaySupplyChainItem(itemIndex);
      selectSupplyChainItem(itemIndex);

  }, [itemIndex, displaySupplyChainItem]);




  if(!data){
    return(
      <PageContainer>
        <Loader metadata={metadata} />
        <FooterNavigator qr_code_hash={qr_code_hash} selected_page={4} />
      </PageContainer>
    )
  }



  return (
      <PageContainer>
        <TitleHeader title={t('filiera')} />

        <div className="page-body">

          { supplyChainItems.length===0 && downward_data && (

            <h2>
              {t('no_data')}
            </h2>

          )}

          { supplyChainItems.length===0 && !downward_data && (

            <Loader  metadata={metadata}  />

          )}

          { supplyChainItems.length>0 && (


              <div>
                  <div>
                    <SupplyChainScroller items={supplyChainItems} callback={selectSupplyChainItem} index={itemIndex} title={""} />
                  </div>
                  <h2>{productName}</h2>
                  { productLotNumber && (
                    <div>
                      {t('lottonumero')} { productLotNumber }
                    </div>
                  )}

                  {mapPositions && mapPositions.length>0 && (
                    <div>
                      <h2>{t('origine')}</h2>
                      <div className="map-outer">
                        <MapRoute points={mapPositions} selected_index={itemIndex} zoomControl={true} />
                      </div>
                    </div>
                  )}

                  <h2>{t('descrizione')}</h2>
                  <p className="gtrans">
                    {productDescription}
                  </p>

                  <Link to={productUrl+"?referer="+qr_code_hash} reloadDocument>
                    <div className="big-button-container">
                      <button className="big-button discover-more"> {t('scopri_di_piu')} </button>
                    </div>
                  </Link>

            </div>

         )}


          <FooterNavigator qr_code_hash={qr_code_hash} selected_page={4} />
        </div>
      </PageContainer>
  );
};

export default SupplyChainPage;
