import React from 'react';
import defaultImage from "../img/doc.png";
import StatsItem from "../components/StatsItem";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const Stats = ({ items }) => {
  const { t } = useTranslation();

  if(!items || items.length===0){
    return <div></div>;
  }

  return (
    <div className="stats-outer">
      <h2>{t('impatto_ambientale')}</h2>
      <div className="stats">

        {
          items.map((item,index)=>(
              <StatsItem imageUrl={item.icon} title={item.title} value={item.value} color={item.color} />
            ))
        }

      </div>
      <div className="stats-infolink">
        <Link style={{display:'none'}} to="www.automorph.net" > {t('come_sono_calcolati')} </Link>
      </div>
    </div>
  );
};

export default Stats;
